import React from "react"
import styled from "styled-components"

const SCComet = styled.svg`
    position: absolute;
    ${props => ({ ...props.position })};
    transform: rotate(${props => `${props.rotate}deg`});
    transform-origin: center;
    z-index: -1;
`

const ThreeComets = ({ width, position, rotate }) => {
    return (
        <SCComet
            position={position}
            width={width}
            rotate={rotate}
            viewBox="0 0 560 696"
            xmlns="http://www.w3.org/2000/svg"
        >
            <defs>
                <linearGradient
                    x1="49.736%"
                    y1="2.235%"
                    x2="47.838%"
                    y2="122.025%"
                    id="gradientA"
                >
                    <stop stopColor="#4b8ac9" offset="0%" />
                    <stop stopColor="#f15f5f" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="52.178%"
                    y1="-4.545%"
                    x2="46.13%"
                    y2="140.499%"
                    id="gradientB"
                >
                    <stop stopColor="#102f4e" offset="0%" />
                    <stop stopColor="#f15f5f" offset="100%" />
                </linearGradient>
            </defs>

            <path
                d="M127 205.434c8.284 0 15 6.716 15 15v370c0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15v-370c0-8.284 6.716-15 15-15z"
                fillOpacity=".09"
                fill="url(#gradientA)"
                transform="rotate(-145 108.892 348)"
            />
            <path
                d="M464.497-20c6.352 0 11.5 5.149 11.5 11.5v277c0 6.351-5.148 11.5-11.5 11.5-6.35 0-11.5-5.149-11.5-11.5v-277c0-6.351 5.15-11.5 11.5-11.5z"
                fillOpacity=".1"
                fill="url(#gradientB)"
                transform="rotate(-145 533.234 348)"
            />
            <path
                d="M464.247 416h.5c6.214 0 11.25 5.037 11.25 11.25v277.5c0 6.213-5.036 11.25-11.25 11.25h-.5c-6.213 0-11.25-5.037-11.25-11.25v-277.5c0-6.213 5.037-11.25 11.25-11.25z"
                fillOpacity=".05"
                fill="url(#gradientB)"
                transform="rotate(-145 395.764 348)"
            />
        </SCComet>
    )
}

export default ThreeComets
