import React from "react"
import styled from "styled-components"

const Animation = styled.svg`
    animation: drawAboutTitle 2.3s forwards linear;
    fill-opacity: 0;
    fill: var(--theme-coral);
    margin-bottom: 80px;
    margin-top: -12px;
    max-width: 800px;
    stroke-dasharray: 950;
    stroke-dashoffset: 1000;
    stroke-width: 2;
    width: 100%;

    .who-text {
        fill: white;
        stroke: white;
    }

    .remaining-text,
    .dots-text {
        fill: var(--theme-coral);
        stroke: var(--theme-coral);
    }

    @keyframes drawAboutTitle {
        to {
            stroke-width: 1;
            stroke-dashoffset: 0;
        }
        50% {
            fill-opacity: 0;
        }
        to {
            fill-opacity: 1;
        }
    }
`

const AboutTitle = () => {
    return (
        <Animation
            viewBox="0 0 567 76"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g transform="translate(50, 0)">
                <path
                    className="who-text"
                    d="M13.3 7.00001C16.7 4.8 20.3333 3.1 24.2 1.90001C28.1333 0.633335 31.9666 0 35.7 0C36.1666 0 36.6666 0.0333349 37.2 0.100005C37.8 0.100005 38.6333 0.133338 39.7 0.200003L40.6 61.7L61.6 0.5H74.7V61.7L96.2 0.5H103.2L75.4 75H60.6V24.1L40.8 75H26.1V6.7C20.7666 8.36667 16.7333 11.0667 14 14.8C11.2666 18.5333 9.89998 23.2333 9.89998 28.9C9.89998 30.1667 9.96664 31.2667 10.1 32.2C10.3 33.1333 10.6 34.0333 11 34.9C11.1333 35.1667 11.2 35.3667 11.2 35.5C11.2666 35.5667 11.3 35.6333 11.3 35.7C7.56664 35.7 4.83331 34.9333 3.09998 33.4C1.43331 31.8 0.599976 29.3333 0.599976 26C0.599976 22.6667 1.69998 19.3333 3.89998 16C6.16664 12.6667 9.29998 9.66667 13.3 7.00001Z"
                    fill="#fff"
                />
                <path
                    className="who-text"
                    d="M120.891 59.8C120.625 61.6 120.491 63.1 120.491 64.3C120.491 67.7667 121.525 70.5333 123.591 72.6C125.658 74.6 128.558 75.6 132.291 75.6C136.491 75.6 142.129 72.5108 145.129 69.4441C144.1 67.8833 143.39 66.0261 143 63.8727C143 63.8727 139.958 65.9 138.291 65.9C136.958 65.9 135.958 65.5667 135.291 64.9C134.625 64.1667 134.291 63.1 134.291 61.7C134.291 60.7667 134.425 59.6 134.691 58.2C134.958 56.8 135.525 54.4 136.391 51C137.458 46.6667 138.158 43.4667 138.491 41.4C138.891 39.2667 139.091 37.4333 139.091 35.9C139.091 32.2333 138.125 29.4333 136.191 27.5C134.258 25.5667 131.525 24.6 127.991 24.6C125.725 24.6 123.625 25.0667 121.691 26C119.758 26.9333 117.958 28.3333 116.291 30.2L121.691 5L106.891 7.00001L92.3914 75H106.791L113.691 42.5C114.158 40.4333 115.125 38.4667 116.591 36.6C118.125 34.6667 119.691 33.7 121.291 33.7C122.491 33.7 123.391 34.1333 123.991 35C124.658 35.8667 124.991 37.1333 124.991 38.8C124.991 39.9333 124.858 41.3333 124.591 43C124.325 44.6667 123.725 47.4 122.791 51.2C121.858 55.1333 121.225 58 120.891 59.8Z"
                    fill="#fff"
                />
                <path
                    className="who-text"
                    d="M142.643 59.6C142.643 55.8 143.243 51.7667 144.443 47.5C145.71 43.2333 147.41 39.4333 149.543 36.1C152.01 32.1667 154.976 29.2 158.443 27.2C161.91 25.1333 165.776 24.1 170.043 24.1C174.31 24.1 177.51 25.4333 179.643 28.1C181.776 30.7667 182.843 34.7667 182.843 40.1C182.976 40.1667 183.11 40.2333 183.243 40.3C183.443 40.3 183.676 40.3 183.943 40.3C186.01 40.3 188.476 39.7333 191.343 38.6C194.21 37.4667 196.876 36.0333 199.343 34.3L200.243 37C198.376 39 195.876 40.7333 192.743 42.2C189.676 43.6667 186.276 44.7 182.543 45.3C181.743 54.3 179.143 61.5667 174.743 67.1C170.343 72.6333 164.976 75.4 158.643 75.4C153.443 75.4 149.476 74.0667 146.743 71.4C144.01 68.7333 142.643 64.8 142.643 59.6ZM170.843 30.2C167.643 30.2 164.61 33.4333 161.743 39.9C158.943 46.3667 157.543 52.5 157.543 58.3C157.543 61.4333 157.876 63.5667 158.543 64.7C159.276 65.8333 160.643 66.4 162.643 66.4C165.11 66.4 167.443 64.4 169.643 60.4C171.91 56.4 173.443 51.4667 174.243 45.6C173.31 45.4 172.61 44.9667 172.143 44.3C171.743 43.5667 171.543 42.6 171.543 41.4C171.543 40.1333 171.81 39.0333 172.343 38.1C172.876 37.1667 173.643 36.4667 174.643 36C174.51 33.8667 174.143 32.3667 173.543 31.5C173.01 30.6333 172.11 30.2 170.843 30.2Z"
                    fill="#fff"
                />
                <path
                    className="remaining-text"
                    d="M212.168 5H242.668V6.9H237.168V73.1H242.668V75H212.168V73.1H217.668V6.9H212.168V5Z"
                    fill="#f15f5f"
                />
                <path
                    className="remaining-text"
                    d="M306.384 73.1L299.084 51.5H279.284L271.584 73.1H279.084V75H263.784V73.1H268.484L293.984 4.4H302.184L327.384 73.1H331.984V75H299.284V73.1H306.384ZM289.584 23.1L280.184 49.3H298.284L289.584 23.1Z"
                    fill="#f15f5f"
                />
                <path
                    className="remaining-text"
                    d="M333.617 27.4H357.717V36.6C359.917 29.8 364.75 26.4 372.217 26.4C379.75 26.4 384.317 30 385.917 37.2C388.251 30 393.15 26.4 400.617 26.4C410.417 26.4 415.317 31.9667 415.317 43.1V73.2H420.917V75H393.217V73.2H396.717V40.7C396.717 35.6333 396.284 32.6667 395.417 31.8C395.017 31.4 394.484 31.2 393.817 31.2C391.884 31.2 390.184 32.5 388.717 35.1C387.25 37.7 386.517 41 386.517 45V73.2H390.117V75H364.417V73.2H367.917V40.7C367.917 35.6333 367.484 32.6667 366.617 31.8C366.217 31.4 365.684 31.2 365.017 31.2C363.15 31.2 361.451 32.6333 359.917 35.5C358.451 38.3 357.717 41.6667 357.717 45.6V73.2H361.317V75H333.617V73.2H339.117V29.2H333.617V27.4Z"
                    fill="#f15f5f"
                />
                <g transform="translate(0, -1)">
                    <path
                        className="dots-text"
                        d="M448.596 72.0252C448.596 73.1321 448.163 74.0713 447.298 74.8428C446.47 75.6143 445.46 76 444.271 76C443.082 76 442.072 75.6143 441.243 74.8428C440.414 74.0713 440 73.1321 440 72.0252C440 70.9182 440.414 69.979 441.243 69.2076C442.072 68.4025 443.082 68 444.271 68C445.46 68 446.47 68.4025 447.298 69.2076C448.163 69.979 448.596 70.9182 448.596 72.0252Z"
                        fill="#f15f5f"
                    />
                    <path
                        className="dots-text"
                        d="M462.798 72.0252C462.798 73.1321 462.365 74.0713 461.5 74.8428C460.672 75.6143 459.662 76 458.473 76C457.284 76 456.274 75.6143 455.445 74.8428C454.617 74.0713 454.202 73.1321 454.202 72.0252C454.202 70.9182 454.617 69.979 455.445 69.2076C456.274 68.4025 457.284 68 458.473 68C459.662 68 460.672 68.4025 461.5 69.2076C462.365 69.979 462.798 70.9182 462.798 72.0252Z"
                        fill="#f15f5f"
                    />
                    <path
                        className="dots-text"
                        d="M477 72.0252C477 73.1321 476.567 74.0713 475.702 74.8428C474.874 75.6143 473.864 76 472.675 76C471.486 76 470.476 75.6143 469.647 74.8428C468.818 74.0713 468.404 73.1321 468.404 72.0252C468.404 70.9182 468.818 69.979 469.647 69.2076C470.476 68.4025 471.486 68 472.675 68C473.864 68 474.874 68.4025 475.702 69.2076C476.567 69.979 477 70.9182 477 72.0252Z"
                        fill="#f15f5f"
                    />
                </g>
            </g>
        </Animation>
    )
}

export default AboutTitle
