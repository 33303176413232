import React, { useLayoutEffect, useRef } from "react"
import styled from "styled-components"

import { isInViewport } from "../../utils/utils"

const SCConnect = styled.svg`
    display: none;
    position: relative;

    @media (min-width: 800px) {
        display: inline-block;
    }

    &.active {
        .line {
            animation: drawCurve 2s linear forwards;
        }

        .dot {
            animation: changeOpacity 660ms linear forwards;
        }
    }

    .dot {
        opacity: 0;
    }

    .line {
        stroke-dasharray: 1000;
        stroke-dashoffset: 1000;
        stroke-width: 4px;
        stroke: var(--theme-coral);
    }

    @keyframes drawCurve {
        to {
            stroke-dashoffset: 0;
        }
    }

    @keyframes changeOpacity {
        0% {
            opacity: 0;
        }
        99% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`

const CurvedConnect = ({ width, classes }) => {
    const ref = useRef(null)

    useLayoutEffect(() => {
        const onScroll = () => {
            if (isInViewport(ref.current)) {
                ref.current.classList.add("active")
            }
        }
        window.addEventListener("scroll", onScroll, true)
        return () => window.removeEventListener("scroll", onScroll)
    }, [])

    return (
        <SCConnect
            ref={ref}
            width={width}
            className={classes}
            viewBox="-4 -14 262 222"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.5 184.5c6.363-68.37 27.037-116.04 62.02-143.01C109.503 14.522 164.83 3.6 240.5 8.73"
                stroke="#f15f5f"
                fill="none"
                className="line"
                strokeLinecap="square"
            />
            <path
                d="M2.126 192.425c-.786 4.926 2.17 9.586 6.768 11.082l2.839.491c4.943.14 9.343-3.406 10.143-8.42.87-5.455-2.845-10.581-8.299-11.452-5.454-.87-10.58 2.846-11.45 8.3z"
                stroke="#f15f5f"
                strokeWidth="4"
                fill="none"
                strokeLinecap="square"
            />

            <path
                className="dot"
                d="M235.126 8.425c-.786 4.926 2.17 9.586 6.768 11.082l2.839.491c4.943.14 9.343-3.406 10.143-8.42.87-5.455-2.845-10.581-8.299-11.452-5.454-.87-10.58 2.846-11.45 8.3z"
                fill="#f15f5f"
                fillRule="nonzero"
            />
        </SCConnect>
    )
}

export default CurvedConnect
