import React from "react"

const Meteor = ({ width }) => {
    return (
        <svg
            viewBox="0 0 181 199"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            className="meteor-svg"
        >
            <defs>
                <linearGradient
                    x1="107.11518%"
                    y1="34.51427%"
                    x2="15.82747%"
                    y2="85.75838%"
                    id="meteorGradient"
                >
                    <stop stopColor="#FFF" offset="0%" />
                    <stop stopColor="#4b8ac9" offset="19.36242%" />
                    <stop stopColor="#102f4e" offset="100%" />
                </linearGradient>
            </defs>
            <path
                d="M135.03404 29.91123C152.8407 41.23678 152.36961 56.34203 160.33078 69.8c7.91407 13.50536 24.26038 25.36343 19.83227 45.17128-4.4281 19.80786-8.05538 38.71536-19.83227 48.09803-11.77688 9.43005-31.70336 9.33528-50.21662 15.35345-18.46615 6.01818-35.56618 18.1493-54.69184 20.23434-19.07855 2.13243-40.22983-5.78124-52.14803-20.94515-11.96531-15.2113 12.8563-48.49618 11.72571-69.9152-1.13058-21.4664-8.66779-35.44669 0-51.13186 8.62068-15.73255 5.8925-22.29926 22.757-34.05129C54.6215 10.86157 71.48599-1.74342 87.1728.19945c15.6397 1.94288 30.1017 18.33885 47.86124 29.71178z"
                fill="url(#meteorGradient)"
                fillRule="nonzero"
                fillOpacity=".1"
            />
        </svg>
    )
}

export default Meteor
