import React from "react"
import styled from "styled-components"

const Asteroids = styled.svg`
    position: absolute;
    ${props => ({ ...props.position })};
`

const ThreeAsteroids = ({ width, position }) => {
    return (
        <Asteroids
            viewBox="0 0 228 187"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            position={position}
        >
            <defs>
                <linearGradient
                    x1="119.11237%"
                    y1="35.51308%"
                    x2="8.64944%"
                    y2="83.45201%"
                    id="threeAsteroidsA"
                >
                    <stop stopColor="#FFF" offset="0%" />
                    <stop stopColor="#4b8ac9" offset="19.36242%" />
                    <stop stopColor="#102f4e" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="116.90092%"
                    y1="34.51427%"
                    x2="9.97257%"
                    y2="85.75838%"
                    id="threeAsteroidsB"
                >
                    <stop stopColor="#FFF" offset="0%" />
                    <stop stopColor="#4b8ac9" offset="19.36242%" />
                    <stop stopColor="#102f4e" offset="100%" />
                </linearGradient>
            </defs>
            <path
                d="M126.14308 16.83829c5.86839 9.54074 11.96977 19.54617 9.14479 27.46049-2.81042 7.91431-12.55743 14.9141-23.90105 14.69627-11.34362-.21783-19.89688-15.21875-30.94436-20.77944-11.04748-5.5607-2.7464-24.27173 2.74339-33.59465 5.48978-9.33744 16.19672-1.7375 22.89513-1.1276 6.71298.62444 14.20826 3.78966 20.0621 13.34493z"
                fillOpacity=".1"
                fill="url(#threeAsteroidsA)"
            />
            <path
                d="M225.84749 139.7381c6.53454 10.69497-3.598 21.17035-6.74366 30.04212-3.12945 8.87178 2.94364 17.45887-9.68765 17.2147-12.63128-.24418-23.02405-16.66623-35.32558-22.89964-12.30153-6.23342-6.11295-21.14607 0-31.59685 6.11296-10.46707 19.7975-7.13938 27.25628-6.45568 7.475.69997 17.98228 2.98411 24.5006 13.69535z"
                fill="url(#threeAsteroidsB)"
                fillOpacity=".1"
                transform="matrix(-1 0 0 1 395 0)"
            />
            <path
                d="M58.84749 118.7381c6.53454 10.69497-3.598 21.17035-6.74366 30.04212-3.12945 8.87178 2.94364 17.45887-9.68765 17.2147-12.63128-.24418-23.02405-16.66623-35.32558-22.89964-12.30153-6.23342-6.11295-21.14607 0-31.59685 6.11296-10.46707 19.7975-7.13938 27.25628-6.45568 7.475.69997 17.98228 2.98411 24.5006 13.69536z"
                fill="url(#threeAsteroidsB)"
                fillOpacity=".1"
                transform="rotate(180 30.5 135)"
            />
        </Asteroids>
    )
}

export default ThreeAsteroids
