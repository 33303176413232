import React from "react"
import styled from "styled-components"

const SCComet = styled.svg`
    position: absolute;
    ${props => ({ ...props.position })};
    z-index: -1;
`

const TwoComets = ({ width, position }) => {
    return (
        <SCComet
            position={position}
            width={width}
            viewBox="0 0 355 331"
            xmlns="http://www.w3.org/2000/svg"
        >
            <defs>
                <linearGradient
                    x1="49.981%"
                    y1="2.235%"
                    x2="49.845%"
                    y2="122.025%"
                    id="twoCometsA"
                >
                    <stop stopColor="#C94B4B" offset="0%" />
                    <stop stopColor="#4B134F" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="49.884%"
                    y1="2.235%"
                    x2="49.047%"
                    y2="122.025%"
                    id="twoCometsB"
                >
                    <stop stopColor="#C94B4B" offset="0%" />
                    <stop stopColor="#4B134F" offset="100%" />
                </linearGradient>
            </defs>
            <g
                transform="matrix(1 0 0 -1 0 331)"
                fill="none"
                fillRule="evenodd"
                opacity=".179"
            >
                <rect
                    fill="url(#twoCometsA)"
                    transform="scale(-1 1) rotate(36 0 -634.632)"
                    x="279"
                    y="111"
                    width="4.847"
                    height="241"
                    rx="2.424"
                />
                <path
                    d="M76-19a6 6 0 016 6v229a6 6 0 01-12 0V-13a6 6 0 016-6z"
                    fill="url(#twoCometsB)"
                    opacity=".144"
                    transform="scale(-1 1) rotate(36 0 -132.404)"
                />
            </g>
        </SCComet>
    )
}

export default TwoComets
