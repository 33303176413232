import React from "react"
import styled from "styled-components"

const Hat = styled.svg`
    transform-box: fill-box;
    transform-origin: center;
    animation: wobble 8s ease-in-out infinite;
    transform: translateZ(0);

    @keyframes wobble {
        0% {
            transform: rotate(0deg);
        }
        1% {
            transform: rotate(5deg);
        }
        2% {
            transform: rotate(-8deg);
        }
        3% {
            transform: rotate(2deg);
        }
        4% {
            transform: rotate(-1deg);
        }
        100% {
            transform: rotate(0deg);
        }
    }
`

const HardHat = ({ width }) => {
    return (
        <Hat
            width={width}
            viewBox="0 0 83 83"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M76.085 44.392a62.286 62.286 0 00-.553-7.226 62.808 62.808 0 00-1.742-8.497C70.182 16.053 62.85 7.696 52.567 4.401v-.25A4.154 4.154 0 0048.417 0H34.583a4.154 4.154 0 00-4.15 4.15v.25C20.151 7.696 12.82 16.053 9.21 28.679a62.59 62.59 0 00-1.741 8.487v.002a62.23 62.23 0 00-.553 7.224A8.295 8.295 0 008.3 60.867h1.006l22.552 13.266a9.676 9.676 0 0019.284 0l22.552-13.266H74.7a8.295 8.295 0 001.385-16.475zm-4.014-11.063c.032.155.055.311.086.466.143.72.25 1.446.368 2.172H59.483v-5.534h11.888c.25.959.497 1.918.7 2.896zM30.433 7.302v6.532a1.383 1.383 0 102.767 0V4.15c0-.764.62-1.383 1.383-1.383h13.834c.763 0 1.382.62 1.383 1.383v9.683a1.383 1.383 0 102.766 0v-6.53c8.563 3.03 14.61 9.875 18.016 20.364H59.283a4.117 4.117 0 00-3.95-2.767h-3.488a12.437 12.437 0 00-20.69 0h-3.488a4.117 4.117 0 00-3.95 2.767H12.419c3.407-10.492 9.454-17.335 18.014-20.365zm22.79 20.365h2.11c.69-.052 1.3.448 1.384 1.135v8.797a1.296 1.296 0 01-1.384 1.135h-3.488a12.344 12.344 0 001.379-11.067zm-2.04 4.15c0 5.348-4.335 9.683-9.683 9.683a9.695 9.695 0 01-9.683-9.683c0-5.348 4.335-9.683 9.683-9.683s9.683 4.335 9.683 9.683zm-20.028 6.917h-3.488c-.69.052-1.3-.448-1.384-1.135v-8.797a1.296 1.296 0 011.384-1.135h2.11a12.344 12.344 0 001.378 11.067zm-20.31-4.944c.03-.152.052-.306.084-.458.203-.98.451-1.94.7-2.899h11.888v5.534H10.476c.117-.727.225-1.457.368-2.177zm21.248 37.27L14.764 60.867h6.986l11.726 7.036a9.638 9.638 0 00-1.383 3.157zm-4.965-10.193h28.745l-8.255 4.953a9.654 9.654 0 00-12.236 0l-8.254-4.953zM41.5 80.234a6.917 6.917 0 116.917-6.917 6.925 6.925 0 01-6.917 6.917zm9.407-9.174a9.64 9.64 0 00-1.383-3.157l11.726-7.036h6.986L50.907 71.06zM74.7 58.1H8.3a5.533 5.533 0 010-11.066h5.533a1.383 1.383 0 100-2.767H9.684c.023-1.202.113-3.156.383-5.533h13.65a4.117 4.117 0 003.95 2.766h6.025a12.402 12.402 0 0015.616 0h6.025a4.118 4.118 0 003.95-2.766h13.65c.27 2.377.36 4.33.383 5.533h-4.15a1.383 1.383 0 000 2.767H74.7a5.533 5.533 0 010 11.066z" />
            <path d="M41.5 69.167a4.15 4.15 0 100 8.3 4.15 4.15 0 000-8.3zm0 5.533a1.383 1.383 0 110-2.766 1.383 1.383 0 010 2.766z" />
        </Hat>
    )
}

export default HardHat
