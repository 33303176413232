import React, { useLayoutEffect, useRef } from "react"
import styled from "styled-components"

import { isInViewport } from "../../utils/utils"

const SCConnect = styled.svg`
    height: 214px;
    position: relative;

    @media (min-width: 800px) {
        display: none;
    }

    &.active {
        .dot {
            animation: changeOpacity 1.3s linear forwards;
        }

        .straight-line {
            animation: drawLine 1s linear forwards;
        }
    }

    .dot {
        opacity: 0;
        stroke-dasharray: 59;
        stroke-dashoffset: 55;
        transform-box: fill-box;
        transform-origin: 50%;
        transform: rotate(167deg);
    }

    .straight-line {
        stroke-dasharray: 250;
        stroke-dashoffset: 250;
        transform-origin: 54%;
        transform: rotate(180deg) translateZ(0);
    }

    @keyframes drawLine {
        to {
            stroke-dashoffset: 492;
        }
    }

    @keyframes changeOpacity {
        0% {
            opacity: 0;
            stroke-dasharray: 59;
        }
        74% {
            opacity: 0;
            stroke-dasharray: 59;
        }
        100% {
            opacity: 1;
            stroke-dasharray: 120;
        }
    }
`
const StraightLine = ({ width, classes }) => {
    const ref = useRef(null)

    useLayoutEffect(() => {
        const onScroll = () => {
            if (isInViewport(ref.current)) {
                ref.current.classList.add("active")
            }
        }

        window.addEventListener("scroll", onScroll, true)
        return () => window.removeEventListener("scroll", onScroll)
    }, [])

    return (
        <SCConnect
            className={classes}
            width={width}
            ref={ref}
            viewBox="0 0 24 282"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.58293 21.4578l3.12286.54009c5.43724.154 10.27718-3.74655 11.15717-9.26189.95698-6.00042-3.12946-11.63895-9.12879-12.59704C8.73484-.81803 3.09631 3.26952 2.13933 9.26885c-.8657 5.41743 2.38587 10.54337 7.4436 12.18895z"
                fill="#f15f5f"
                fillRule="nonzero"
            />
            <path
                d="M8.89437 279.50733l2.839.491c4.943.14 9.343-3.406 10.143-8.42.87-5.455-2.845-10.581-8.299-11.452-5.454-.87-10.58 2.846-11.45 8.3-.787 4.925 2.169 9.585 6.767 11.081z"
                stroke="#f15f5f"
                strokeWidth="4"
                fill="none"
                className="dot"
                strokeLinecap="square"
            />
            <line
                className="straight-line"
                x1="13"
                y1="14"
                x2="13"
                y2="260"
                strokeWidth="4px"
                stroke="#f15f5f"
            />
        </SCConnect>
    )
}

export default StraightLine
