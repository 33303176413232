import React from "react"

import styled from "styled-components"

const Asteroids = styled.svg`
    left: 60vw;
    width: 900px;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
`

const HeroAsteroid = () => (
    <Asteroids viewBox="0 0 887 615" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <linearGradient
                x1="119.112%"
                y1="35.513%"
                x2="8.649%"
                y2="83.452%"
                id="heroAsteroidA"
            >
                <stop stopColor="#FFF" offset="0%" />
                <stop stopColor="#102f4e" offset="19.362%" />
                <stop stopColor="#4a8cce" offset="100%" />
            </linearGradient>
            <linearGradient
                x1="116.901%"
                y1="34.514%"
                x2="9.973%"
                y2="85.758%"
                id="heroAsteroidB"
            >
                <stop stopColor="#FFF" offset="0%" />
                <stop stopColor="#102f4e" offset="19.362%" />
                <stop stopColor="#4a8cce" offset="100%" />
            </linearGradient>
            <linearGradient
                x1="119.112%"
                y1="34.514%"
                x2="8.649%"
                y2="85.758%"
                id="heroAsteroidC"
            >
                <stop stopColor="#FFF" offset="0%" />
                <stop stopColor="#102f4e" offset="19.362%" />
                <stop stopColor="#4a8cce" offset="100%" />
            </linearGradient>
        </defs>
        <g fillRule="nonzero" fill="none" fillOpacity=".2">
            <path
                d="M656.143 458.838c5.868 9.541 11.97 19.546 9.145 27.46-2.81 7.915-12.558 14.915-23.901 14.697-11.344-.218-19.897-15.219-30.945-20.78-11.047-5.56-2.746-24.271 2.744-33.594 5.49-9.337 16.197-1.738 22.895-1.128 6.713.625 14.208 3.79 20.062 13.345z"
                fill="url(#heroAsteroidA)"
                transform="rotate(180 443.5 307.5)"
            />
            <path
                d="M884.847 110.738c6.535 10.695-3.598 21.17-6.743 30.042-3.13 8.872 2.943 17.46-9.688 17.215-12.631-.244-23.024-16.666-35.325-22.9-12.302-6.233-6.113-21.146 0-31.597 6.113-10.467 19.797-7.139 27.256-6.455 7.475.7 17.982 2.984 24.5 13.695z"
                fill="url(#heroAsteroidB)"
                transform="matrix(1 0 0 -1 -826 615)"
            />
            <path
                d="M31.243 336.313c3.759 2.39 8.213 3.71 9.893 6.55 1.67 2.851.567 7.222-.368 11.402-.934 4.18-1.7 8.171-4.186 10.152-2.485 1.99-6.69 1.97-10.598 3.24-3.897 1.27-7.507 3.83-11.543 4.27-4.027.45-8.49-1.22-11.006-4.42-2.526-3.21-3.102-7.951-3.34-12.472-.24-4.53-.14-8.84 1.69-12.151 1.819-3.32 5.368-5.631 8.928-8.111 3.559-2.48 7.118-5.141 10.429-4.73 3.3.41 6.353 3.87 10.101 6.27zM89.243 507.313c3.759 2.39 8.213 3.71 9.893 6.55 1.67 2.851.567 7.222-.368 11.402-.934 4.18-1.7 8.171-4.186 10.152-2.485 1.99-6.69 1.97-10.598 3.24-3.897 1.27-7.507 3.83-11.543 4.27-4.027.45-8.49-1.22-11.006-4.42-2.526-3.21-3.102-7.951-3.34-12.472-.24-4.53-.14-8.84 1.69-12.151 1.819-3.32 5.368-5.631 8.928-8.111 3.559-2.48 7.118-5.141 10.429-4.73 3.3.41 6.353 3.87 10.101 6.27zM73.243 6.313c3.759 2.39 8.213 3.71 9.893 6.55 1.67 2.851.567 7.222-.368 11.402-.934 4.18-1.7 8.171-4.186 10.152-2.485 1.99-6.69 1.97-10.598 3.24-3.897 1.27-7.507 3.83-11.543 4.27-4.027.45-8.49-1.22-11.006-4.42-2.526-3.21-3.102-7.951-3.34-12.472-.24-4.53-.14-8.84 1.69-12.151 1.819-3.32 5.368-5.631 8.928-8.111 3.559-2.48 7.118-5.141 10.429-4.73 3.3.41 6.353 3.87 10.101 6.27z"
                fill="url(#heroAsteroidC)"
                transform="rotate(180 443.5 307.5)"
            />
            <path
                d="M455.847 567.738c6.535 10.695-3.598 21.17-6.743 30.042-3.13 8.872 2.943 17.46-9.688 17.215-12.631-.244-23.024-16.666-35.325-22.9-12.302-6.233-6.113-21.146 0-31.597 6.113-10.467 19.797-7.139 27.256-6.455 7.475.7 17.982 2.984 24.5 13.695z"
                fill="url(#heroAsteroidB)"
                transform="translate(32 -553)"
            />
            <path
                d="M336.847 334.738c6.535 10.695-3.598 21.17-6.743 30.042-3.13 8.872 2.943 17.46-9.688 17.215-12.631-.244-23.024-16.666-35.325-22.9-12.302-6.233-6.113-21.146 0-31.597 6.113-10.467 19.797-7.139 27.256-6.455 7.475.7 17.982 2.984 24.5 13.695z"
                fill="url(#heroAsteroidB)"
                transform="translate(270 -87)"
            />
        </g>
    </Asteroids>
)

export default HeroAsteroid
