import React from "react"

import styled from "styled-components"

const Comets = styled.svg`
    position: absolute;
    width: 100%;
    max-width: 900px;
    top: -160px;
    left: 50px;
`

const HeroComets = () => {
    return (
        <Comets viewBox="0 0 830 590" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <linearGradient
                    x1="49.981%"
                    y1="2.235%"
                    x2="49.845%"
                    y2="122.025%"
                    id="heroCometsA"
                >
                    <stop stopColor="#102f4e" offset="0%" />
                    <stop stopColor="#4b8ac9" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="49.884%"
                    y1="2.235%"
                    x2="49.047%"
                    y2="122.025%"
                    id="heroCometsB"
                >
                    <stop stopColor="#102f4e" offset="0%" />
                    <stop stopColor="#4b8ac9" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="49.736%"
                    y1="2.235%"
                    x2="47.838%"
                    y2="122.025%"
                    id="heroCometsC"
                >
                    <stop stopColor="#4b8ac9" offset="0%" />
                    <stop stopColor="#102f4e" offset="100%" />
                </linearGradient>
                <linearGradient
                    x1="52.178%"
                    y1="-4.545%"
                    x2="46.13%"
                    y2="140.499%"
                    id="heroCometsD"
                >
                    <stop stopColor="#102f4e" offset="0%" />
                    <stop stopColor="#102f4e" offset="100%" />
                </linearGradient>
            </defs>
            <g fill="none" fillRule="evenodd">
                <rect
                    fill="url(#heroCometsA)"
                    transform="scale(-1 1) rotate(36 0 -375.632)"
                    x="279"
                    y="370"
                    width="4.847"
                    height="241"
                    rx="2.424"
                />
                <path
                    d="M76 240a6 6 0 016 6v229a6 6 0 01-12 0V246a6 6 0 016-6z"
                    fill="url(#heroCometsB)"
                    opacity=".144"
                    transform="scale(-1 1) rotate(36 0 126.596)"
                />
                <path
                    d="M397.003 83.434c8.284 0 15 6.716 15 15v370c0 8.284-6.716 15-15 15-8.285 0-15-6.716-15-15v-370c0-8.284 6.715-15 15-15z"
                    fillOpacity=".6"
                    fill="url(#heroCometsC)"
                    transform="scale(-1 1) rotate(35 0 -975.697)"
                />
                <path
                    d="M171.25-20h.5C177.963-20 183-14.963 183-8.75v277.5c0 6.213-5.037 11.25-11.25 11.25h-.5c-6.213 0-11.25-5.037-11.25-11.25V-8.75c0-6.213 5.037-11.25 11.25-11.25z"
                    fillOpacity=".4"
                    fill="url(#heroCometsD)"
                    transform="scale(-1 1) rotate(35 0 -413.929)"
                />
                <path
                    d="M734.25 294h.5c6.213 0 11.25 5.037 11.25 11.25v277.5c0 6.213-5.037 11.25-11.25 11.25h-.5c-6.213 0-11.25-5.037-11.25-11.25v-277.5c0-6.213 5.037-11.25 11.25-11.25z"
                    fillOpacity=".4"
                    fill="url(#heroCometsD)"
                    transform="scale(-1 1) rotate(35 0 -1885.536)"
                />
            </g>
        </Comets>
    )
}

export default HeroComets
