import React from "react"
import styled from "styled-components"

import SocialIcons from "./social-icons"
import loadable from "@loadable/component"
const ContactModalNonSSR = loadable(() => import("./contact-modal"))
const ProjectModalNonSSR = loadable(() => import("./project-modal"))

const FooterWrapper = styled.footer`
    align-items: center;
    background-color: var(--selected-secondary-color);
    color: white;
    display: flex;
    font-size: 1rem;
    font-weight: bold;
    padding: var(--container-padding);
    position: relative;
    background: linear-gradient(
        -90deg,
        var(--selected-primary-color) 10%,
        var(--selected-secondary-color)
    );

    .last-name {
        font-family: serif;
        color: var(--theme-coral);
    }
`

const Footer = ({ theme }) => {
    const copyrightYear = new Date().getFullYear()

    return (
        <FooterWrapper>
            <ContactModalNonSSR />
            <ProjectModalNonSSR theme={theme} />
            <span>
                &copy; Rafael <span className="last-name">Heard</span>,{" "}
                {copyrightYear}
            </span>
            <SocialIcons />
        </FooterWrapper>
    )
}

export default Footer
