import React from "react"
import styled from "styled-components"

const Asteroid = styled.svg`
    position: absolute;
    ${props => ({ ...props.position })};
`
const SingleAsteriod = ({ width, position }) => {
    return (
        <Asteroid
            viewBox="0 0 42 42"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            position={position}
        >
            <defs>
                <linearGradient
                    x1="119.112%"
                    y1="34.514%"
                    x2="8.649%"
                    y2="85.758%"
                    id="singleAsteriodGradient"
                >
                    <stop stopColor="#FFF" offset="0%" />
                    <stop stopColor="#4b8ac9" offset="19.362%" />
                    <stop stopColor="#102f4e" offset="100%" />
                </linearGradient>
            </defs>
            <path
                d="M31.243 16.313c3.759 2.39 8.213 3.71 9.893 6.55 1.67 2.851.567 7.222-.368 11.402-.934 4.18-1.7 8.171-4.186 10.152-2.485 1.99-6.69 1.97-10.598 3.24-3.897 1.27-7.507 3.83-11.543 4.27-4.027.45-8.49-1.22-11.006-4.42C.909 44.297.333 39.556.095 35.035c-.24-4.53-.14-8.84 1.69-12.151 1.819-3.32 5.368-5.631 8.928-8.111 3.559-2.48 7.118-5.141 10.429-4.73 3.3.41 6.353 3.87 10.101 6.27z"
                transform="translate(0 -10)"
                fill="url(#singleAsteriodGradient)"
                fillRule="nonzero"
                fillOpacity=".1"
            />
        </Asteroid>
    )
}

export default SingleAsteriod
