import React from "react"
import styled from "styled-components"

const SCCurve = styled.svg`
    height: 50px;
    margin: 0 calc(-50vw + 50%) -6px;
    position: relative;
    transition: height 0.3s;
    width: 100vw;

    @media (min-width: 800px) {
        height: 200px;
    }
`

const Curve = () => {
    return (
        <SCCurve
            className="curve"
            preserveAspectRatio="none"
            viewBox="0 -1 2692 267"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0 .77c448.667 143.449 897.333 215.174 1346 215.174 448.667 0 897.333-71.725 1346-215.175V266.7H0V.77z"
                fill="#FFF"
                fillRule="evenodd"
            />
        </SCCurve>
    )
}

export default Curve
