import React from "react"
import styled from "styled-components"

const SCLogo = styled.svg`
    left: 26px;
    position: absolute;
    top: 20px;
    transition: width 0.3s, opacity 0.25s, transform 0.25s;
    width: 80px;
    z-index: 100;

    @media (min-width: 800px) {
        width: 100px;
        top: 20px;
        left: 60px;
    }

    @media (min-width: 1100px) {
        left: 85px;
        top: 20px;
        width: 120px;
    }
`

const Logo = () => {
    return (
        <SCLogo viewBox="0 0 594 598" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fillRule="evenodd">
                <g fillRule="nonzero">
                    <path
                        d="M364.11 511.855c-39.528 0-76.696-15.398-104.657-43.359a3.999 3.999 0 115.656-5.656c26.454 26.45 61.606 41.015 99 41.015 37.391-.007 72.536-14.566 98.985-41.023 26.453-26.45 41.015-61.602 41.023-98.984 0-37.399-14.57-72.551-41.023-99.008a3.999 3.999 0 115.656-5.656c27.965 27.96 43.367 65.136 43.367 104.664-.008 39.52-15.41 76.687-43.367 104.64-27.953 27.961-65.113 43.36-104.64 43.367z"
                        fill="#5D9CEC"
                    />
                    <path
                        d="M349.688 598c-65.247 0-126.6-25.417-172.754-71.57a6.6 6.6 0 010-9.337c2.573-2.573 6.758-2.573 9.337 0 43.665 43.658 101.69 67.702 163.417 67.702 61.72-.013 119.731-24.044 163.39-67.715 43.666-43.658 67.704-101.682 67.717-163.388 0-61.731-24.051-119.755-67.717-163.426a6.6 6.6 0 010-9.336c2.573-2.573 6.758-2.573 9.337 0C568.575 227.083 594 288.447 594 353.692c-.013 65.233-25.437 126.584-71.585 172.724-46.141 46.154-107.48 71.571-172.727 71.584z"
                        fill="#5D9CEC"
                    />
                    <path
                        d="M465.926 266.016c-1.028 0-2.051-.391-2.832-1.168l-22.625-22.625a4.006 4.006 0 010-5.657 4.006 4.006 0 015.656 0l22.625 22.625a4.006 4.006 0 010 5.657 3.984 3.984 0 01-2.824 1.168zM431.988 232.078a4.015 4.015 0 01-2.832-1.168l-11.312-11.308a3.999 3.999 0 115.656-5.656l11.313 11.308a4.006 4.006 0 010 5.656 3.984 3.984 0 01-2.825 1.168zM250.973 458.352a4.015 4.015 0 01-2.832-1.168l-5.657-5.657a4.006 4.006 0 010-5.656 4.006 4.006 0 015.657 0l5.656 5.656a4.006 4.006 0 010 5.657 3.984 3.984 0 01-2.824 1.168zM352.797 164.191a4.015 4.015 0 01-2.832-1.168L248.14 61.2a4.006 4.006 0 010-5.656 4.006 4.006 0 015.656 0l101.832 101.824a4.006 4.006 0 010 5.656 4.015 4.015 0 01-2.832 1.168z"
                        fill="#5D9CEC"
                    />
                    <path
                        d="M217.035 73.688a4.015 4.015 0 01-2.832-1.168l-33.941-33.954a3.999 3.999 0 115.656-5.656l33.941 33.945a3.999 3.999 0 010 5.657 3.953 3.953 0 01-2.824 1.175z"
                        fill="#4B8AC9"
                    />
                    <path
                        d="M103.89 254.703a4.015 4.015 0 01-2.831-1.168l-67.88-67.879a4.006 4.006 0 010-5.656 4.006 4.006 0 015.657 0l67.879 67.879a3.999 3.999 0 01-2.824 6.824z"
                        fill="#5D9CEC"
                    />
                </g>
                <circle
                    stroke="#F15F5F"
                    strokeWidth="25"
                    cx="346"
                    cy="352"
                    r="213.5"
                />
                <path
                    d="M457.414 270.535C436.43 249.465 233.54 111.816 224.91 105.97c-1.312-.906-3.058-.914-4.402-.059a4.011 4.011 0 00-1.793 4.035c.496 2.934.715 6.871-1.047 8.957-1.191 1.41-3.473 2.13-6.758 2.13h-.012C168.98 121.031 23.438 13.304 6.563.702 4.98-.48 2.91-.097 1.508 1.305.102 2.71.109 5.152 1.3 6.75 65.812 93.16 130.59 195.465 120.348 215.887c-.68 1.36-2.016 2.922-6.313 2.922-1.168 0-2.445-.121-3.84-.352-1.55-.281-3.16.438-4.023 1.79a4.005 4.005 0 00.055 4.401c5.851 8.633 143.503 211.52 164.57 232.582 24.894 24.899 58.023 38.61 93.27 38.61 35.25 0 68.402-13.711 93.335-38.61 24.938-24.933 38.676-58.101 38.676-93.375 0-35.289-13.738-68.421-38.664-93.32z"
                    fill="#F15F5F"
                    fillRule="nonzero"
                />
                <path
                    d="M158.626 358.617c-1.023 0-2.05-.39-2.832-1.168l-22.625-22.62a3.999 3.999 0 115.656-5.656l22.625 22.62a4.006 4.006 0 010 5.656 3.984 3.984 0 01-2.824 1.168z"
                    fill="#5D9CEC"
                    fillRule="nonzero"
                />
            </g>
        </SCLogo>
    )
}

export default Logo
