import React from "react"
import styled from "styled-components"

import ThemeContext from "../context/ThemeContext"

const SocialWrapper = styled.div`
    margin-left: auto;

    a,
    button {
        text-decoration: none;
        outline: none;
        padding: 0 10px;
        margin-left: 15px;

        &:hover,
        &:focus {
            svg {
                fill: var(--theme-coral);
            }
        }
    }

    svg {
        fill: white;
        height: 24px;
        transition: fill 0.3s;
        width: 24px;
    }

    button {
        background: none;
        border: none;
        cursor: pointer;
        padding-bottom: 0;
        padding-top: 0;
    }
`

const SocialIcons = () => {
    return (
        <ThemeContext.Consumer>
            {theme => (
                <SocialWrapper>
                    <a
                        href="https://www.linkedin.com/in/roheard"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                            viewBox="0 0 24 24"
                        >
                            <path d="M4.98 3.5C4.98 4.881 3.87 6 2.5 6S.02 4.881.02 3.5C.02 2.12 1.13 1 2.5 1s2.48 1.12 2.48 2.5zM5 8H0v16h5V8zm7.982 0H8.014v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0V24H24V13.869c0-7.88-8.922-7.593-11.018-3.714V8z" />
                        </svg>
                        <span className="screen-reader-text">
                            Connect on LinkedIn
                        </span>
                    </a>
                    <a
                        href="https://github.com/rafh"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                            viewBox="0 0 24 24"
                        >
                            <path d="M12 0C5.374 0 0 5.373 0 12c0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23A11.509 11.509 0 0112 5.803c1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576C20.566 21.797 24 17.3 24 12c0-6.627-5.373-12-12-12z" />
                        </svg>
                        <span className="screen-reader-text">View GitHub</span>
                    </a>
                    {/* <button onClick={theme.toggleDark}>
                        <svg
                            role="img"
                            viewBox="0 0 25 25"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M12.5 2.083c5.744 0 10.417 4.673 10.417 10.417S18.244 22.917 12.5 22.917V2.083zM12.5 0C5.597 0 0 5.597 0 12.5S5.597 25 12.5 25 25 19.403 25 12.5 19.403 0 12.5 0z"
                                fillRule="nonzero"
                            />
                            <title>Toggle Dark Mode</title>
                        </svg>
                    </button> */}
                </SocialWrapper>
            )}
        </ThemeContext.Consumer>
    )
}

export default SocialIcons
