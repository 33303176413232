import React from "react"

const MeteorTwo = ({ width }) => {
    return (
        <svg
            viewBox="0 0 201 203"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            className="meteor-svg"
        >
            <defs>
                <linearGradient
                    x1="117.79629%"
                    y1="34.51427%"
                    x2="9.43686%"
                    y2="85.75838%"
                    id="meteorTwoGradient"
                >
                    <stop stopColor="#FFF" offset="0%" />
                    <stop stopColor="#4b8ac9" offset="19.36242%" />
                    <stop stopColor="#102f4e" offset="100%" />
                </linearGradient>
            </defs>
            <path
                d="M179.87234 57.8019C188.2241 71.4775 186.19148 88.05642 190 102c5.27003 19.29438 14.08936 35.66416 8.52608 51.3618-9.53433 27.04193-42.60097 50.95905-81.0841 50.21478-38.48314-.74428-67.5-52-104.97849-71-22.5067-11.40995-8.66814-43.97118 0-71.2774 5.76617-18.16447 2.25738-31.36139 9.69726-44.08668 18.62406-31.90452 54.5569-5.3602 77.28123-3.27623 22.77371 2.13359 60.57128 11.21683 80.43036 43.86563z"
                transform="translate(0 -1)"
                fill="url(#meteorTwoGradient)"
                fillRule="nonzero"
                fillOpacity=".1"
            />
        </svg>
    )
}

export default MeteorTwo
