import React from "react"
import styled from "styled-components"

const SCComet = styled.svg`
    position: absolute;
    ${props => ({ ...props.position })};
    z-index: -1;
`

const OneComet = ({ width, position }) => {
    return (
        <SCComet
            position={position}
            width={width}
            viewBox="0 0 183 250"
            xmlns="http://www.w3.org/2000/svg"
        >
            <defs>
                <linearGradient
                    x1="52.178%"
                    y1="-4.545%"
                    x2="46.13%"
                    y2="140.499%"
                    id="oneCometA"
                >
                    <stop stopColor="#C94B4B" offset="0%" />
                    <stop stopColor="#C94B4B" offset="100%" />
                </linearGradient>
            </defs>
            <path
                d="M95.5 33c6.351 0 11.5 5.149 11.5 11.5v277c0 6.351-5.149 11.5-11.5 11.5S84 327.851 84 321.5v-277C84 38.149 89.149 33 95.5 33z"
                transform="rotate(-145 84.356 154.63)"
                fill="url(#oneCometA)"
                fillRule="evenodd"
                fillOpacity=".4"
                opacity=".179"
            />
        </SCComet>
    )
}

export default OneComet
