import React from "react"
import styled from "styled-components"

const Flame = styled.path`
    transform-box: fill-box;
    transform: translateX(0) translateY(0) translateZ(0);

    @keyframes ignite {
        0% {
            opacity: 1;
            transform: translate(-2px, 2px);
        }
        50% {
            opacity: 0.9;
            transform: translate(-6px, 6px);
        }
        100% {
            opacity: 0.8;
            transform: translate(-2px, 2px);
        }
    }
`

const RocketShip = styled.svg`
    transform-box: fill-box;
    transform: translateX(0) translateY(0) translateZ(0);

    animation: ${props => (props.animate ? "zoom 8s linear infinite" : "none")};

    @keyframes zoom {
        0% {
            transform: scale(1) translate(2px, -2px);
        }
        25% {
            transform: scale(0.9) translate(-5px, 5px);
        }
        50% {
            transform: scale(0.8) translate(-8px, 8px);
        }
        75% {
            transform: scale(0.95) translate(4px, -4px);
        }
        100% {
            transform: scale(1) translate(2px, -2px);
        }
    }

    ${Flame} {
        animation: ${props =>
            props.animate ? "ignite 5s ease infinite" : "none"};
    }
`

const Rocket = ({ width, animate = false }) => {
    return (
        <RocketShip
            width={width}
            animate={animate}
            viewBox="0 0 151 158"
            xmlns="http://www.w3.org/2000/svg"
            fill="#f15f5f"
        >
            <Flame d="M61.27948 113.33152l-4.34896-.4048-.38804 4.1686c-.18398 1.97298-.93933 3.82897-2.18607 5.36856-1.6811 2.07598-4.06983 3.37375-6.72713 3.65305l-11.24052 1.18142-1.18142-11.24051c-.2793-2.6573.49347-5.26366 2.17457-7.33964 1.24673-1.5396 2.90513-2.66432 4.79674-3.25448l3.99683-1.24627-1.30016-4.16976-3.99683 1.24627c-2.71729.84623-5.10042 2.46332-6.89148 4.6751-2.41512 2.98242-3.52622 6.72851-3.1235 10.54533l1.4097 13.41244c.06296.59904.3628 1.11728.79735 1.46917.43454.35189 1.0038.53743 1.60284.47447l13.41244-1.4097c3.81714-.39965 7.25036-2.26533 9.66547-5.24775 1.79107-2.21178 2.87735-4.87905 3.14012-7.7129l.38805-4.1686z" />
            <path d="M93.71907 119.90142c7.226-11.83532 9.4624-26.31429 6.16798-39.78021l5.9395-7.33468c10.2804-12.69523 15.00032-28.63413 13.2943-44.88066l-.02971-.28262c-.12625-1.20116-1.19904-2.06989-2.4002-1.94364l-.28263.0297c-16.24483 1.7074-30.85547 9.63976-41.13586 22.33499l-5.93951 7.33468c-13.85661-.42271-27.5542 4.77517-37.62852 14.30396l-8.6956 8.22536c-.7585.718-.90419 1.8732-.34555 2.75559.13182.20511.29177.38242.4717.52813.5958.48247 1.42182.6286 2.16617.3376l4.19835-1.63395c6.12021-2.37948 12.99168-1.6155 18.4438 1.89734l-1.28385 1.58542c-.75877.93699-.61411 2.31325.32288 3.07202l1.69745 1.37456-4.1237 5.09235c-.75877.937-.61412 2.31326.32288 3.07202l8.48725 6.87284-5.49827 6.7898 3.3949 2.74914 5.49827-6.7898 8.48725 6.87284c.937.75876 2.31326.61411 3.07202-.32288l4.1237-5.09235 1.69745 1.37457c.937.75876 2.31326.6141 3.07202-.32289l1.28248-1.58372c4.571 4.60091 6.74558 11.16508 5.69055 17.64628l-.72536 4.44632c-.1268.78826.18617 1.56447.78198 2.04694.17993.1457.38662.2653.61603.3499.97926.36297 2.07755-.01803 2.62353-.91091l6.23632-10.21404zm21.22764-89.61405c.47907 6.70044-.23087 13.31775-2.03018 19.61783L96.17967 36.35196c5.78832-3.06985 12.11342-5.14009 18.76704-6.0646zM33.02357 74.44704l1.68231-1.59158c8.3975-7.944 19.54487-12.5679 31.04768-13.05632L50.7103 78.37604c-5.2117-3.50084-11.54967-4.89239-17.68672-3.929zm33.27647 33.2677l-16.9745-13.74569 2.74913-3.3949 16.9745 13.74568-2.74913 3.3949zm8.89317-4.04067L51.42891 84.4301l27.23844-33.63667c3.88178-4.7936 8.43459-8.84708 13.47822-12.08713l19.3085 15.63571c-2.12232 5.60569-5.14063 10.90177-9.02242 15.69537l-27.23844 33.63668zm6.06981-.5541l15.04465-18.5786c1.91246 11.3547-.29444 23.21845-6.31583 33.08378l-1.20703 1.97646c-.33363-6.20325-3.0125-12.1134-7.52179-16.48164z" />
            <path d="M93.55156 56.70818c-5.61686-4.54844-13.88367-3.67957-18.43211 1.9373-4.54845 5.61686-3.67957 13.88366 1.93729 18.4321 5.61686 4.54845 13.88367 3.67958 18.43211-1.93729 4.54845-5.61686 3.67957-13.88366-1.9373-18.4321zm-13.74568 16.9745c-3.74458-3.0323-4.32383-8.5435-1.29153-12.28807 3.0323-3.74457 8.5435-4.32383 12.28807-1.29153 3.74458 3.0323 4.32383 8.5435 1.29153 12.28808-3.0323 3.74457-8.5435 4.32382-12.28807 1.29153z" />
        </RocketShip>
    )
}

export default Rocket
