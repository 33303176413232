import React from "react"

import styled from "styled-components"

const Curve = styled.svg`
    height: 300px;
    margin: 0 calc(-50vw + 50%) -6px;
    position: relative;
    width: 100vw;

    position: absolute;
    width: 100vw;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
`
const LargeCurve = () => {
    return (
        <Curve
            preserveAspectRatio="none"
            viewBox="0 0 1444 1291"
            xmlns="http://www.w3.org/2000/svg"
        >
            <defs>
                <linearGradient
                    x1="99.24567%"
                    y1="50.60786%"
                    x2="0%"
                    y2="50.74152%"
                    id="gradient1"
                >
                    <stop stopColor="#4B8AC9" offset="0%" />
                    <stop stopColor="#C94B4B" offset="46.1355%" />
                    <stop stopColor="#FFF" offset="100%" />
                </linearGradient>
            </defs>
            <path
                d="M1.409 246.5c238.604-75.861 437.584-88.985 596.94-39.373 126.466 39.373 254.54 149.727 538.133 64.865C1207.786 250.655 1310.292 159.991 1444 0v1290.213H0L1.409 246.5z"
                fill="url(#gradient1)"
                fillRule="evenodd"
                fillOpacity=".1"
            />
        </Curve>
    )
}

export default LargeCurve
