import React from "react"
import styled, { createGlobalStyle } from "styled-components"
import { Link } from "gatsby"

import SocialIcons from "../social-icons"
import {
    HeroComets,
    HeroAsteroid,
    Curve,
    Name,
    AboutTitle,
    Logo,
} from "../svg/index"
import Button from "../button"

const GlobalStyle = createGlobalStyle`
    nav {
        align-items: center;
        display: flex;
        justify-content: flex-end;
        padding: var(--container-padding) 10px;
        position: absolute;
        right: 0;

        @media (min-width: 600px) {
            padding: var(--container-padding);
        }

        span {
            font-size: 1.8rem;
            position: relative;
        }
    }

    .logo-link {
        &:hover,
        &:focus {
            svg {
                transform: scale(1.1);
                opacity: 0.7;
            }
        }
    }

    .nav-link {
        align-self: center;
        color: white;
        padding: 10px;
        position: relative;
        text-decoration: none;
        position: relative;

        &:hover,
        &:focus {
            outline: none;

            &:before {
                width: 25px; 
            }
        }

        &:before {
            background: var(--theme-coral);
            border-radius: 10px;
            bottom: 0px;
            content: "";
            height: 5px;
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
            transition: width 0.25s linear;
            width: 0px;
        }
    }

    .is-current-page{

        &:hover {
            &:before {
                width: 25px; 
            }
        }
        
        &:before {
            content: "";
            transition: width 0.25s linear;
            width: 12px;
            height: 5px;
            border-radius: 10px;
        }
    }
`

const HeroWrapper = styled.div`
    background: linear-gradient(
        126deg,
        var(--selected-primary-color) -35%,
        var(--selected-secondary-color)
    );

    display: flex;
    flex-direction: column;
    font-size: 1.6rem;
    height: auto;
    max-height: 900px;
    min-height: 700px;
    overflow: hidden;
    position: relative;

    .bevel {
        left: 0;
        max-width: 170px;
        top: 0;
        fill: white;
        width: 100%;

        @media (min-width: 800px) {
            max-width: 400px;
            width: 400px;
            height: 290px;
            min-height: 290px;
            margin-bottom: 100px;
        }
    }
`

const SCContent = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 900px;
    padding: var(--container-padding);
    position: relative;
    width: 100%;
    z-index: 10;

    button {
        font-size: 1.5rem;
        min-width: 130px;
        margin: 0 10px 20px;

        .text {
            padding: 11px;
        }

        @media (min-width: 600px) {
            min-width: 162px;
            font-size: 1.6rem;

            .text {
                padding: 11px 16px;
            }
        }
    }
`

const SCButtonContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`

const Hero = ({ theme, location }) => {
    return (
        <HeroWrapper>
            <GlobalStyle />
            <Link className="logo-link" to="/">
                <span className="screen-reader-text">Comet Logo</span>
                <Logo />
            </Link>
            <svg
                className="bevel"
                viewBox="0 0 554 405"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M593.936-60H-55.39L-165 189.45l339.232 201.409c45.462 26.991 104.079 13.93 133.78-29.808L593.937-60z"
                    fillRule="evenodd"
                />
            </svg>
            <HeroComets />
            <HeroAsteroid />
            <nav>
                <Link
                    className="nav-link"
                    activeClassName="is-current-page"
                    to="/about"
                >
                    <span>About</span>
                </Link>
                <SocialIcons />
            </nav>
            <SCContent>
                {location.pathname === "/" && <Name />}
                {location.pathname === "/about" && <AboutTitle />}
                {location.pathname === "/about/" && <AboutTitle />}

                <SCButtonContainer>
                    <Button
                        text="Contact Me"
                        attributes={{
                            "data-micromodal-trigger": "contact-modal",
                        }}
                    />
                    <Button
                        text="Request a Quote"
                        event={() => theme.utilizeScroll("quote-anchor")}
                    />
                </SCButtonContainer>
            </SCContent>
            <Curve />
        </HeroWrapper>
    )
}

export default Hero
